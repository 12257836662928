<template>
  <div class="row">
    <div class="col-12 col-md-2">
      <input-select-search
        ref="anoLetivoId"
        :label="$t(`ACADEMICO.ANO_LETIVO`)"
        :options="opcoes.anosLetivos"
        v-model="filtro.anoLetivoId"
        required
      />
    </div>
    <div class="col-12 col-md-3">
      <input-select-search
        ref="cursoId"
        class="w-100"
        :label="$t(`ACADEMICO.CURSO`)"
        :options="opcoes.cursos"
        v-model="filtro.cursoId"
        :isDisabled="desabilitar.curso"
        required
      />
    </div>
    <div class="col-12 col-md-3">
      <input-select-search
        class="w-100"
        ref="grupoTurmaId"
        :label="$t(`ACADEMICO.GRUPOS_TURMA`)"
        :options="opcoes.gruposTurma"
        v-model="filtro.grupoTurmaId"
        :isDisabled="desabilitar.grupoTurma"
        required
      />
    </div>
    <b-col cols="12" md="auto">
      <b-button
        class="w-100 botao-acao-filtro"
        variant="primary"
        @click="filtrar"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col cols="12" md="auto">
      <b-button
        class="w-100 botao-acao-filtro mt-3"
        variant="secondary"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
  </div>
  <!-- </template> -->
  <!-- </filtro> -->
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import CursoService from '@/common/services/academico/curso.service';
import GrupoTurmaService from '@/common/services/grupoTurma/grupo-turma.service';

// Components:
// import Filtro from '@/components/filtro/Filtro';
import { InputSelectSearch } from '@/components/inputs';

export default {
  components: {
    // Filtro,
    InputSelectSearch,
  },
  data() {
    return {
      filtro: {
        anoLetivoId: '',
        cursoId: '',
        grupoTurmaId: '',
      },
      desabilitar: {
        grupoTurma: true,
        curso: true,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        gruposTurma: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivo();
    this.$emit('refs', this.$refs);
  },
  watch: {
    filtro: {
      handler() {
        this.grupoTurmaId
          ? this.$emit('filtrar', JSON.parse(JSON.stringify(this.filtro)))
          : {};
      },
      deep: true,
    },
    'filtro.anoLetivoId'(anoLetivoId) {
      if (anoLetivoId) {
        this.getCursos(anoLetivoId);
      } else {
        this.opcoes.cursos = [];
        this.opcoes.gruposTurma = [];
        this.desabilitar.curso = true;
        this.desabilitar.grupoTurma = true;
      }
      this.filtro.cursoId = null;
      this.filtro.grupoTurmaId = null;
    },
    'filtro.cursoId'(cursoId) {
      if (cursoId) {
        this.getGruposTurma();
      } else {
        this.desabilitar.grupoTurma = true;
        this.opcoes.gruposTurma = [];
      }
      this.filtro.grupoTurmaId = null;
    },
  },
  methods: {
    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.ano,
            value: row.id,
          }));
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...data,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGruposTurma() {
      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
        this.filtro.cursoId,
        this.filtro.anoLetivoId
      )
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.gruposTurma = data;
          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    // FUNÇÕES FILTRO:
    validarFiltro() {
      return helpers.validarFormulario(this.$refs);
    },
    filtrar() {
      if (!this.validarFiltro()) return;
      this.$emit('filtrar', JSON.parse(JSON.stringify(this.filtro)));
    },
    limparFiltro() {
      this.filtro = {
        cursoId: '',
        turmaId: '',
      };
      this.$emit('limparFiltro');
    },
  },
};
</script>
