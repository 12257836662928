<template>
  <topbar
    :titulo="$t('ACADEMICO.TURMAS')"
    :subtitulo="subtituloHTML"
    :breadcrumbs="breadcrumbs"
  />
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO.ESTRUTURA_CEJ') },
        { titulo: this.$t('ACADEMICO.TURMAS') },
      ],
      subtituloHTML: this.$t('ACADEMICO.TURMAS_CEJ_DESCRICAO'),
    };
  },
  methods: {},
};
</script>
