<template>
  <div>
    <titulo-turmas />

    <filtro @filtrar="filtrar" @limparFiltro="limparTabela" />

    <div>
      <b-row class="justify-content-end">
        <b-col cols="12" md="auto">
          <b-button
            variant="info"
            class="my-3 mb-md-3 w-100"
            @click="openModal('turma')"
            :disabled="btnCadastrar"
          >
            {{ $t('ACADEMICO.TURMA_CADASTRAR') }}
          </b-button>
        </b-col>
      </b-row>
      <tabela-turmas
        :turmas="resultadoBusca"
        @editar="openModal"
        @excluir="confirmarExclusao"
      />
    </div>

    <modal-turma
      :form="modais.turmaDados"
      :exibir="modais.turma"
      :filtro="filtro"
      @atualiza-tabela="filtrar"
      @fechar="closeModal('turma')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';

// Services:
import TurmasService from '@/common/services/academico/turma.service.js';

// Components:
import Filtro from '@/views/academico/turmas/components/Filtro.vue';
import TabelaTurmas from '@/views/academico/turmas/components/TabelaTurmas.vue';
import ModalTurma from '@/views/academico/turmas/components/ModalTurma.vue';
import TituloTurmas from '@/views/academico/cej/estrutura/turmas/components/TitulosTurmas';
export default {
  components: {
    Filtro,
    TituloTurmas,
    TabelaTurmas,
    ModalTurma,
  },
  data() {
    return {
      filtro: {},
      resultadoBusca: [],
      modais: {
        turma: false,
        turmaDados: {},
      },
      btnCadastrar: true,
    };
  },
  methods: {
    // FUNÇÕES FILTRO:
    filtrar(filtroUtilizado) {
      filtroUtilizado ? (this.filtro = filtroUtilizado) : {};
      this.$store.dispatch(START_LOADING);
      TurmasService.buscarPorCursoIdGrupoTurmaId(this.filtro)
        .then(({ data }) => {
          this.resultadoBusca = data;
          this.btnCadastrar = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limparTabela() {
      this.btnCadastrar = true;
      this.resultadoBusca = [];
    },
    // FUNÇÕES MODAL
    openModal(key, item) {
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = {});
      this.modais[key] = true;
    },
    closeModal() {
      this.modais.turma = false;
    },
    // FUNÇÕES CRUD
    async confirmarExclusao(item) {
      let itemExclusao = this.resultadoBusca.filter(
        (itemBusca) => itemBusca.id == item
      );
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('ACADEMICO.TURMA_CONFIRMAR_EXCLUSAO_TITULO', {
          item: itemExclusao[0]?.descricao ? itemExclusao[0].descricao : '',
        }),
        this.$t('ACADEMICO.TURMA_CONFIRMAR_EXCLUSAO_DESCRICAO')
      );
      if (respostaExclusao.isConfirmed) {
        this.excluir(item);
      }
    },
    excluir(turmaId) {
      this.$store.dispatch(START_LOADING);
      TurmasService.excluir(turmaId)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('ACADEMICO.TURMA_EXCLUIDA')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.filtrar();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
